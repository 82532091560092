import { useState } from 'react';
import './App.css';
import Globe from 'react-globe.gl';


function App() {

  const markerSvg = `
    <svg viewBox="0 0 30 30" width="16" height="16">
    <circle cx="15.0013" cy="15.0024" r="14.9794" transform="rotate(-30 15.0013 15.0024)" fill="currentColor"/>
    </svg>
  `

  const [isToggled, setIsToggled] = useState(true);

  const lightMode = {
    color: "rgba(0,10,84",
    backgroundColor: "#F0F0F0",
    actionColor: "rgba(0,10,84",
    image: "earth-blue-marble4.jpg",
    label: "Lightmode"
  }

  const darkMode = {
    color: "rgba(230,230,230",
    backgroundColor: "#000A54",
    actionColor: "rgba(0,219,77",
    image: "earth-blue-marble-darkmode.jpg",
    label: "Darkmode"
  }

 const coordinates = [
  {lat: 132, lng: 190},
  {lat: 150, lng: 115},
  {lat: 148, lng: 105}
]

const coordinatesWorld = [
  {lat: 132, lng: 190},
  {lat: 134, lng: 192},
  {lat: 132, lng: 194},
  {lat: 136, lng: 192},
  {lat: 150, lng: 115},
  {lat: 148, lng: 105}
]

const initialViewState = {
  latitude: 30, // set the latitude of the location
  longitude: 122, // set the longitude of the location
  zoom: 3, // set the initial zoom level of the globe
  altitude: 0, // set the altitude of the camera
  bearing: 0, // set the bearing of the camera
  pitch: 0, // set the pitch of the camera
};

const gData = coordinatesWorld.map(coord => ({
  lat: coord.lat,
  lng: coord.lng,
  size: 24
}));

  const rData = coordinatesWorld.map(coord => ({
    lat: coord.lat,
    lng: coord.lng,
    maxR: 8,
    propagationSpeed: 2,
    repeatPeriod: 700
  }));

  const toggle = () => {
    setIsToggled(!isToggled);
  };

  const colorInterpolator = t => `${isToggled ? lightMode.actionColor : darkMode.actionColor},${Math.sqrt(1-t)})`;

  const initialCoordinates = [40.7128, -74.006];
  const initialCameraDistance = 300;

  return (
    <>
      <Globe 
        initialCoordinates={initialCoordinates}
        initialCameraDistance={initialCameraDistance}
        htmlElementsData={gData} 
        globeImageUrl= {isToggled ? lightMode.image : darkMode.image}
        backgroundColor= {isToggled ? lightMode.backgroundColor : darkMode.backgroundColor}
        showAtmosphere={!isToggled}
        ringsData={rData}
        ringColor={() => colorInterpolator}
        ringMaxRadius="maxR"
        ringPropagationSpeed="propagationSpeed"
        ringRepeatPeriod="repeatPeriod"
        htmlElement={d => {
          const el = document.createElement('div');
          el.innerHTML = markerSvg;
          el.style.color = `${isToggled ? lightMode.actionColor : darkMode.actionColor},1)`;
          el.style['pointer-events'] = 'auto';
          el.style.cursor = 'pointer';
          el.onmouseover = () => {
    el.style.color = "#009626"
  };
  el.onmouseout = () => {
    el.style.color = `${isToggled ? lightMode.actionColor : darkMode.actionColor},1)`;
  };
          return el;
        }}
        >
      </Globe>
      <button className='Button' style={{color: `${isToggled ? lightMode.color : darkMode.color},1)`, backgroundColor: `${isToggled ? lightMode.backgroundColor : darkMode.backgroundColor}`}} onClick={toggle}>Mode: {isToggled ? lightMode.label : darkMode.label} </button>
    </>
  );
}

export default App;
